import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./404.scss"

const NotFoundPage = () => (
  <Layout hideFooter>
    <SEO title="404: Not found" />
    <div className="NotFoundPage">
      <p className="NotFoundPage--panel">
        <span className="NotFoundPage--number">404.</span>
        <span className="NotFoundPage--line">
          The page that you want's been unlisted,
        </span>
        <span className="NotFoundPage--line">
          first ripped from the site and then twisted
        </span>
        <span className="NotFoundPage--line">by digital wights</span>
        <span className="NotFoundPage--line">into meaningless bytes</span>
        <span className="NotFoundPage--line">
          (or maybe it never existed).
        </span>
      </p>
      <Link className="NotFoundPage--link" to="/">
        why don't we try this from the top?
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
